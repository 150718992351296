<template>
  <v-footer
    id="dashboard-core-footer"
    class="secondary footer pb-12"
    width="100vw"
  >
    <v-container class="pa-0">
      <v-row class="d-flex justify-center">
        <v-col class="d-flex justify-center">
          <logo />
        </v-col>
      </v-row>
      <v-row class="d-flex justify-center align-center mx-2">
        <!-- <v-col
          cols="4"
          class="d-flex justify-center"
        >
          <v-menu
            v-model="productMenuValue"
            offset-y
            origin="bottom right"
          >
            <template v-slot:activator="{ on, attrs }">
              <div
                class="footer-text"
                color="#FFFFFF"
                text
                v-bind="attrs"
                :class="{active: productMenuValue}"
                v-on="on"
              >
                Products
                <v-icon
                  right
                  color="white"
                >
                  mdi-chevron-down
                </v-icon>
              </div>
            </template>

            <v-list>
              <v-list-item
                v-for="(item, index) in menuitems"
                :key="index"
                class="blanketv2accent--text"
                :to="item.route"
              >
                <v-list-item-title>{{ item.title }}</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </v-col> -->
        <v-col
          cols="5"
          class="d-flex justify-center"
        >
          <v-menu
            v-model="aboutMenuValue"
            offset-y
            origin="bottom right"
          >
            <template v-slot:activator="{ on, attrs }">
              <div
                class="footer-text"
                color="#FFFFFF"
                text
                v-bind="attrs"
                :class="{active: aboutMenuValue}"
                v-on="on"
              >
                {{ $t("menu.about") }}
                <v-icon
                  right
                  color="white"
                >
                  mdi-chevron-down
                </v-icon>
              </div>
            </template>

            <v-list>
              <v-list-item
                v-for="(item, index) in aboutitems"
                :key="index"
                class="blanketv2accent--text"
                :to="item.route"
              >
                <v-list-item-title>{{ $t(`menu.${toCamelCase(item.title)}`) }}</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </v-col>
        <v-col
          cols="5"
        >
          <div
            class="footer-text d-flex justify-center"
            @click="$router.push('/contactus')"
          >
            {{ $t('menu.contact') }}
          </div>
        </v-col>
        <v-col
          cols="5"
          class="pt-0"
        >
          <span
            class="footer-text text-center d-flex justify-center pt-1"
            @click="e=>{
              if(userCanadian){
                return $router.push('/privacy-ca')
              }
              return $router.push('/privacy')
            }"
          >{{ $t('menu.privacyPolicy') }}</span>
        </v-col>
        <v-col
          v-if="userCanadian"
          cols="5"
          class="pt-0 align-complaint"
        >
          <a
            target="_blank"
            href="/complaints-ca"
            class="footer-text d-flex justify-center pt-1"
          >
            {{ $t('menu.complaints') }}

          </a>
        </v-col>
      </v-row>
      <v-row class="d-flex justify-center ma-0 pa-0">
        <v-col
          cols="11"
          class="ma-0 pa-0"
        >
          <v-divider
            class="white"
            horizontal
          ></v-divider>
        </v-col>
      </v-row>
      <v-row>
        <v-col
          cols="12"
          class="d-inline-flex justify-center"
        >
          <!-- <v-icon
            class="px-2"
            color="white"
          >
            mdi-facebook
          </v-icon>
          <v-icon
            class="px-2"
            color="white"
          >
            mdi-linkedin
          </v-icon>
          <v-icon
            class="px-2"
            color="white"
          >
            mdi-twitter
          </v-icon>
          <v-icon
            class="px-2"
            color="white"
          >
            mdi-youtube
          </v-icon>
          <v-icon
            class="px-2"
            color="white"
          >
            mdi-instagram
          </v-icon> -->
          <v-btn
            text
            small
            href="https://www.linkedin.com/company/applywithblanket/"
            target="_blank"
            class="px-0"
            color="transparent"
          >
            <v-icon
              class="px-2"
              color="white"
            >
              mdi-linkedin
            </v-icon>
          </v-btn>
        </v-col>
      </v-row>
      <v-row>
        <v-col class="footer-btm-txt">
          ©{{ this.currentYear }} {{ regionalCompanyName }} DBA Blanket Insurance
        </v-col>
      </v-row>
      <v-row class="d-flex justify-center">
        <v-col cols="11">
          <v-divider
            horizontal
            class="white"
          ></v-divider>
        </v-col>
      </v-row>
      <v-row class="secondary mt-0">
        <v-col class="white--text pb-0 mb-0 pt-0 tiny-font px-6">
          <p
            v-for="(item,index) in $t('disclaimer')"
            :key="index"
          >
            {{ item }}
          </p>
        </v-col>
      </v-row>
    </v-container>
  </v-footer>
</template>

<script>
  import { toCamelCase } from '../../../../utils/normalizeToEnglish'
  import Logo from './Logo'
  import moment from 'moment'
  export default {
    name: 'DashboardCoreFooter',
    components: {
      Logo
    },
    data: () => ({
      productMenuValue: null,
      aboutMenuValue: null,
      links: [
        {
          href: '#',
          text: 'About Us',
        },
        {
          href: '#',
          text: 'News',
        },
        {
          href: '#',
          text: 'Licenses',
        },
      ],
      aboutitems: [
        { title: 'Our Team', route: '/about/team' },
        { title: 'Partnerships', route: '/about/partnerships' },
        {
          title: 'GGA Create Partnerships',
          route: '/about/ggacreatepartnership',
        }],
      menuitems: [
        // { title: 'Main', route: '/' },
        { title: 'Pet', route: '/pet' },
        // { title: 'Auto', route: '/auto' },
        // { title: 'US Term Life', route: '/afficiencyterm' },
        // { title: 'Homeowners', route: '/homeowners' },
        // { title: 'Term Life', route: '/termlifehome' },
        { title: 'Travel', route: '/travel/usa' },
        // { title: 'Canadian Term', route: '/canadianterm' },
        // { title: 'Canadian Term R&C', route: '/canadiantermrc' },
        { title: 'Renters', route: '/renters' },
        // { title: 'Landlord', route: '/landlord' },
        // { title: 'Condo', route: '/condo' },
        // { title: 'Business', route: '/business' },
      ],
      currentYear: moment().format('YYYY')
    }),
    computed: {
      userCanadian () {
        const getCountry = this.$store.getters.getWebsiteLocation || this.$Storage.getCookie('location')
        return getCountry === 'CA'
      },
      regionalCompanyName () {
        if (this.$store.getters.getWebsiteLocation === 'CA') {
          return '10555193 Canada Inc.'
        }
        return 'Yeroosha Inc'
      },
      mediumDevice () {
        return this.$vuetify.breakpoint.mdAndUp
      },
      mobileDevice () {
        return this.$vuetify.breakpoint.xsOnly
      },
    },
    methods: {
      toCamelCase,
      navigate (route) {
        this.$router.push(route)
      }
    }
  }
</script>

<style scoped>
.tiny-font {
  font-size: 9px !important;
}
.mobile-footer {
  margin-top: -59px !important;
}
  .footer-text {
    color: white;
    font-family: 'Poppins';
font-style: normal;
font-weight: 400;
font-size: 14px !important;
line-height: 21px;
text-decoration: none;
text-transform: none !important;
  }

@media screen and (max-width: 401px) {
  .footer-text {
font-size: 11px !important;
}
}

  .footer-text2 {
    font-family: 'Poppins';
font-style: normal;
font-weight: 500;
font-size: 12px;
line-height: 24px;
color: #FFFFFF;
  }

  .login {
    font-family: 'Roboto';
font-style: normal;
font-weight: 400;
font-size: 20px;
line-height: 23px;
display: flex;
align-items: flex-end;
text-transform: capitalize;

color: #FFFFFF;
  }

  .btn {
    text-transform: none !important;
    font-size: 20px;
    font-weight: 500;
}

.ins-btn {
  font-size: 17px;
  font-weight: 500;
  text-transform: none !important;
}

.tabs {
  background-color: #005F6C;
  color: white;
}

.footer-items {
  font-family: 'Roboto';
font-style: normal;
font-weight: 400;
font-size: 20px;
line-height: 23px;
display: flex;
align-items: flex-end;
text-transform: capitalize;

color: #FFFFFF;
}

.v-btn.active .v-icon {
    transform: rotate(-180deg);
  }

  .footer-btm-txt {
font-family: 'Poppins';
font-style: normal;
font-weight: 400;
font-size: 12px;
line-height: 24px;
text-align: center;
color: #FFFFFF;
  }
 .logo-img-fr{
   max-height: 42px!important;
  }

  .align-complaint {
    position: relative;
    top: 8px;
  }

</style>
